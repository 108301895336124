import AspectRatio from '@lib/AspectRatio';
import classnames from 'classnames';
import React from 'react';

export interface BannerProps {
  image: string;
  className?: string;
  upperCaption?: string;
  lowerCaption?: string;
  link?: string;
}

const Banner: React.FunctionComponent<BannerProps> = ({ image, className = '', lowerCaption, upperCaption, link }) => {
  const banner = (
    <AspectRatio aspectRatio={1200 / 400}>
      <div
        className={`bg-cover bg-no-repeat w-full h-full ${className}`}
        style={{ backgroundImage: `url('${image}')`, backgroundSize: 'cover', backgroundPositionX: 'center' }}
      >
        <div
          className={classnames('flex flex-col items-center justify-center h-full w-full', {
            'bg-black bg-opacity-50': upperCaption || lowerCaption,
          })}
        >
          <div
            className="text-white text-18 md:text-32 font-light w-full text-center"
            font-customisation="para-text"
            color-customisation="banner-subHeading"
          >
            {upperCaption}
          </div>
          {lowerCaption ? (
            <div
              className="mb-44 text-white text-24 md:text-56 font-medium w-full text-center"
              font-customisation="section-heading"
              color-customisation="banner-heading"
            >
              {lowerCaption}
            </div>
          ) : null}
        </div>
      </div>
    </AspectRatio>
  );
  return link ? (
    <a href={link} rel="noreferrer">
      {banner}
    </a>
  ) : (
    <>{banner}</>
  );
};

export default Banner;
