export const PRODUCT_CARD_WIDTH = 300;
export const PRODUCT_CARD_HEIGHT = 300;

export const CATEGORY_IMAGE_WIDTH = 524;
export const CATEGORY_IMAGE_HEIGHT = 250;

export const BANNER_IMAGE_WIDTH = 780;
export const BANNER_IMAGE_HEIGHT = 622;

export const MOBILE_CATEGORY_IMAGE_WIDTH = 360;
export const MOBILE_CATEGORY_IMAGE_HEIGHT = 250;

export const MOBILE_BANNER_IMAGE_WIDTH = 390;
export const MOBILE_BANNER_IMAGE_HEIGHT = 268;

export const TESTIMONIALS_IMAGE_WIDTH = 160;
export const TESTIMONIALS_IMAGE_HEIGHT = 160;

export const MOBILE_TESTIMONIALS_IMAGE_WIDTH = 92;
export const MOBILE_TESTIMONIALS_IMAGE_HEIGHT = 92;

export const DEFAULT_LAYOUT_SELECTION = 15;
export const DEFAULT_NAVBAR_LAYOUT_SELECTION = 'PH1';
export const DEFAULT_SLIDER_LAYOUT_SELECTION = 'HB8';
export const DEFAULT_PRODUCTLIST_LAYOUT_SELECTION = 'PS1';
export const DEFAULT_CATEGORYLIST_LAYOUT_SELECTION = 'CS2';
export const DEFAULT_TESTIMONIALS_LAYOUT_SELECTION = 'Testimonial3';
export const DEFAULT_TEXT_LAYOUT_SELECTION = 'Text1';

export const SHOW_ALL_PRODUCTS_IN_HOME = false;

export const SECTION_HEADING_CLASSNAME = 'uppercase font-bold';
export const SLIDER_TOP_PADDING = 'pt-0';
export const LAYOUT_CONFIG = {
  productList: {
    ShowTaxTag: false,
    showDiscountLabel: false,
  },
};
export const COMMON_TOAST_MESSAGE = {};
