import React from 'react';

interface AspectRatioProps {
  aspectRatio?: number;
  className?: string;
  children: React.ReactNode;
}

const AspectRatio: React.FC<AspectRatioProps> = ({ aspectRatio = 1, className, children }) => {
  return (
    <div
      className={`relative h-0 w-full ${className}`}
      style={{
        paddingBottom: `${(1 / aspectRatio) * 100}%`,
      }}
    >
      <div className="absolute inset-0">{children}</div>
    </div>
  );
};

export default AspectRatio;
