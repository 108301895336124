import Banner from '@common/components/Banner';
import { ReactComponent as SliderRight } from '@common/icons/SliderRight.svg';
import { ReactComponent as SliderSelected } from '@common/icons/SliderSelected.svg';
import { ReactComponent as SliderUnselected } from '@common/icons/SliderUnselected.svg';
import DefaultBackgroundImage from '@common/images/DefaultBannerImage.png';
import { Banner as BannerType } from '@common/models/sections/Banner';
import Slider, { getFilteredSliderImages } from '@common/models/Slider';
import { SliderSliceType } from '@common/store/slider/slice';
import Carousel from '@lib/Carousel';
import React from 'react';

interface SliderProps {
  banner: BannerType;
  slider: SliderSliceType;
}

const SliderComponent: React.FC<SliderProps> = ({ banner, slider }) => {
  if (!banner.sliderEnabled) {
    return <Banner lowerCaption={banner.bannerText} image={DefaultBackgroundImage} />;
  }

  const filteredImages = getFilteredSliderImages({ slider });

  if (!filteredImages?.length) {
    return null;
  }
  return (
    <Carousel
      autoplayMilliSeconds={5000}
      items={filteredImages}
      // lazyLoad={true}
      renderSlide={({ chunkedItem }) => {
        const banners = chunkedItem as Slider[];
        return banners.map((banner, index) => (
          <Banner
            key={index}
            upperCaption={banner.subheading}
            lowerCaption={banner.heading}
            link={banner.link}
            image={banner.image || DefaultBackgroundImage}
          />
        ));
      }}
      renderControls={({ items, gotoPrevious, gotoNext, current, gotoSlide, isFirstSlide, isLastSlide }) => {
        return (
          <div>
            {!isFirstSlide ? (
              <div className="absolute left-0 top-0 h-full flex items-center ml-18">
                <button className="focus:outline-none" onClick={() => gotoPrevious()} style={{ top: '50%' }}>
                  <SliderRight className="transform rotate-180" />
                </button>
              </div>
            ) : null}
            {!isLastSlide ? (
              <div className="absolute right-0 top-0 h-full flex items-center mr-18">
                <button className="focus:outline-none" onClick={() => gotoNext()}>
                  <SliderRight />
                </button>
              </div>
            ) : null}
            <div className="absolute bottom-0 left-0 w-full flex justify-center">
              <div className="flex items-center">
                {[...Array(items.length).keys()].map((_, index) => (
                  <div className="mx-8 mb-14" key={index}>
                    {index === current ? (
                      <SliderSelected className="cursor-not-allowed" />
                    ) : (
                      <SliderUnselected className="cursor-pointer" onClick={() => gotoSlide(index)} />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

export default SliderComponent;
