import React from 'react';
export interface CtaProps {
  text: string;
  customClass?: string;
  link: string;
}

const Cta: React.FC<CtaProps> = ({ link, customClass, text, ...props }) => {
  return (
    <>
      {text && link ? (
        <a
          href={link}
          className={`w-136 min-h-48 py-8 px-12 text-center text-14 md:text-16 font-medium bg-theme border border-theme text-white focus:outline-none rounded-md disabled:opacity-20 max-w-220 flex justify-center items-center ${customClass}`}
          target="_blank"
          rel="noreferrer"
          font-customisation="para-text"
          button-customisation="page-button"
          {...props}
        >
          {text}
        </a>
      ) : null}
    </>
  );
};

export default Cta;
