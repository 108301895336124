import { ReactComponent as ToastCloseIcon } from '@common/icons/ToastClose.svg';
import { ToastWithKeyType } from '@common/store/toasts/slice';
import { addToast, removeToast } from '@common/store/toasts/slice';
import { WISHLIST_TOAST } from '@common/store/wishList/slice';
import { ReactComponent as AddToCartToastCloseIcon } from '@images/CrossSign.svg';
import classNames from 'classnames';
import React from 'react';
export interface ToastsProps {
  toasts: ToastWithKeyType[];
  addToast: typeof addToast;
  removeToast: typeof removeToast;
  quickView?: boolean;
  type?: string;
  productImage?: string;
}

const Toasts: React.FC<ToastsProps> = ({ toasts, removeToast, type, productImage }) => {
  setTimeout(() => {
    if (toasts.length) {
      document.getElementById('QuickViewToasts')?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, 1000);

  return (
    <div>
      {toasts.map((toast) => (
        <>
          {!(type === 'addedToCart' && toast.variant === 'bg-toast-success') && (
            <div
              key={toast.key}
              className={toast.variant === 'bg-toast-error' ? 'text-errorToast' : 'text-successToast'}
            >
              <div
                className={classNames(
                  'container mx-auto flex items-center my-4 py-8 px-16 border  text-15 justify-between',
                  {
                    'border-errorToast': toast.variant === 'bg-toast-error',
                    'border-successToast': toast.variant === 'bg-toast-success',
                  },
                )}
                font-customisation="para-text"
              >
                <div dangerouslySetInnerHTML={{ __html: toast.content }}></div>
                <div style={{ filter: 'brightness(0.1)' }} onClick={() => removeToast(toast.key)}>
                  <ToastCloseIcon className="cursor-pointer" />
                </div>
              </div>
            </div>
          )}
          {type === 'addedToCart' && toast.variant === 'bg-toast-success' && (
            <div
              className="z-1 px-20 md:px-0 fixed w-full md:w-fit-content  max-w-522 md:right-20"
              font-customisation="para-text"
            >
              <div className="flex p-10 border-theme border rounded-sm md:rounded-md bg-white relative">
                <img src={productImage} className="flex mr-12 w-80 h-80" />
                <div className="flex flex-col justify-center">
                  {toast?.options?.find((option) => option?.name == WISHLIST_TOAST) ? null : (
                    <div className="text-theme mb-12 text-14">Added to cart</div>
                  )}
                  <div className="text-heading text-16" dangerouslySetInnerHTML={{ __html: toast.content }}></div>
                </div>
                <div
                  className="absolute cursor-pointer h-24 w-24 bg-white rounded-full flex items-center justify-center border border-theme right-20"
                  style={{
                    right: 20,
                    top: -12,
                  }}
                  onClick={() => removeToast(toast.key)}
                >
                  <AddToCartToastCloseIcon className="h-12 w-12" />
                </div>
              </div>
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export default Toasts;
